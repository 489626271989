import _ from "lodash"
import main from "~styles/main.scss"

export default _.mapValues(main, val => {
  // @HACK: because Webpack exports strings wrapped in double quotes
  if (typeof val === "string") {
    val = val.replace(/['"]+/g, "")
  }

  return val
})
