import React from "react"
import { navigate } from "gatsby"
import _ from "lodash"
import { injectIntl, FormattedMessage } from "react-intl"
import url from "url"
import Button from "~components/_layout/button"
import Label from "~components/_layout/label"
import utils from "../../utils"
import validation from "./validation"

const Register = ({ agreements, register, isAuth }) => {
  let loc

  if (typeof window !== "undefined") {
    loc = url.parse(window.location.href, true)
  } else {
    loc = {}
  }

  const initialState = {
    provider: "emailpassword",
    form: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      personal_data_processing_agreement: false,
      ref_user_id: _.get(loc, "query.ref"),
    },
    loading: true,
    validated: false,
    error: false,
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_AS_VALIDATED":
        return {
          ...state,
          validated: true,
          error: false,
        }
      case "SET_ERROR_STATE":
        return {
          ...state,
          error: action.payload,
        }
      case "EDIT_FORM_DATA":
        return {
          ...state,
          form: {
            ...state.form,
            ...action.payload,
          },
        }
      default:
        return state
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState)

  const isValid = field => {
    let obj = state.form

    return validation(obj)[field] && validation(obj)[field]()
  }

  // const switchToPassword = () => {
  //   this.setState({
  //     provider: "emailpassword",
  //     validated: true,
  //     form: {
  //       ...this.state.form,
  //       password: "",
  //       password_confirmation: "",
  //     },
  //   })

  //   setTimeout(() => {
  //     const bodyRect = document.body.getBoundingClientRect()
  //     const passwordTop = document
  //       .getElementById("password-field")
  //       .getBoundingClientRect().top

  //     window.scrollTo({
  //       top: passwordTop - bodyRect.top,
  //       behavior: "smooth",
  //     })
  //   }, 300)
  // }

  const onSubmit = async (e, register) => {
    e.preventDefault()

    dispatch({
      type: "SET_ERROR_STATE",
      payload: false,
    })

    dispatch({
      type: "SET_AS_VALIDATED",
    })

    if (
      !utils.validate(
        validation,
        _.merge(state.form, { provider: state.provider })
      )
    ) {
      return
    }

    try {
      // if (state.provider === "facebook") {
      //   await facebookLogin()
      // }

      await register({ data: state.form })
      navigate("/")
    } catch (e) {
      console.log("onSubmit error", e.message)

      let errorCode = 500

      if (e.message === "Request failed with status code 409") {
        errorCode = 409
      }

      dispatch({
        type: "SET_ERROR_STATE",
        payload: errorCode,
      })

      return null
    }
  }

  React.useEffect(() => {
    if (isAuth) {
      navigate("/")
    }
  }, [isAuth])

  return (
    <>
      <form className="has-text-left" onSubmit={e => onSubmit(e, register)}>
        {state.error && (
          <div className="notification is-danger margin-bottom-24">
            <FormattedMessage id={`register.server_error.${state.error}`} />
          </div>
        )}

        <div className="field">
          <Label>register.first_name.label</Label>

          <div className="control">
            <input
              className={`input ${
                !isValid("first_name") && state.validated ? "is-danger" : ""
              }`}
              type="text"
              value={state.form.first_name}
              onChange={e =>
                dispatch({
                  type: "EDIT_FORM_DATA",
                  payload: { first_name: e.target.value },
                })
              }
            />
          </div>
        </div>

        <div className="field">
          <Label>register.last_name.label</Label>

          <div className="control">
            <input
              className={`input ${
                !isValid("last_name") && state.validated ? "is-danger" : ""
              }`}
              type="text"
              value={state.form.last_name}
              onChange={e =>
                dispatch({
                  type: "EDIT_FORM_DATA",
                  payload: { last_name: e.target.value },
                })
              }
            />
          </div>
        </div>

        <div className="field">
          <Label>register.email.label</Label>

          <div className="control">
            <input
              className={`input ${
                !isValid("email") && state.validated ? "is-danger" : ""
              }`}
              type="email"
              value={state.form.email}
              onChange={e =>
                dispatch({
                  type: "EDIT_FORM_DATA",
                  payload: { email: e.target.value },
                })
              }
            />
          </div>
        </div>

        <div className="field">
          <Label>register.password.label</Label>

          <div className="control">
            <input
              className={`input ${
                !isValid("password") && state.validated ? "is-danger" : ""
              }`}
              type="password"
              value={state.form.password}
              onChange={e =>
                dispatch({
                  type: "EDIT_FORM_DATA",
                  payload: { password: e.target.value },
                })
              }
            />
          </div>
        </div>

        <div className="field">
          <Label>register.password_confirmation.label</Label>

          <div className="control">
            <input
              className={`input ${
                !isValid("password_confirmation") && state.validated
                  ? "is-danger"
                  : ""
              }`}
              type="password"
              value={state.form.password_confirmation}
              onChange={e =>
                dispatch({
                  type: "EDIT_FORM_DATA",
                  payload: { password_confirmation: e.target.value },
                })
              }
            />
          </div>
        </div>

        <p className="has-text-grey margin-top-24 margin-bottom-16">
          <FormattedMessage id="register.pre_terms" />
        </p>

        {agreements &&
          agreements.map((agreement, i) => (
            <div
              key={i}
              className="margin-bottom-8"
              dangerouslySetInnerHTML={{
                __html: agreement.text.childMarkdownRemark.html,
              }}
            />
          ))}

        <Button
          className="is-success is-medium is-fullwidth margin-top-32"
          type="submit"
        >
          register.register
        </Button>
      </form>
    </>
  )
}

export default injectIntl(Register)
