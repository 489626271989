import React from "react"
import styles from "./index.styles"

const Tag = ({ element = "span", id, className = "", children }) =>
  React.createElement(
    element,
    { className: `${styles} ${className}`, id },
    children
  )

export default Tag
