export default state => ({
  first_name: () => state.first_name && state.first_name.length > 1,
  last_name: () => state.last_name && state.last_name.length > 1,
  email: () => /^.+@.+\..+$/.test(state.email),
  password: () => state.password && state.password.length > 5,
  password_confirmation: () =>
    state.password_confirmation &&
    state.password_confirmation.length > 5 &&
    state.password === state.password_confirmation,
})
