import { css, cx } from "emotion"

export const logoCss = ({ width }) =>
  cx(
    css`
      img {
        width: ${width || 100}px;
      }
    `,
    "margin-bottom-32"
  )
