import React from "react"
import { FormattedMessage } from "react-intl"

const Label = ({ children }) => (
  <label className="label">
    <FormattedMessage id={children} />
  </label>
)

export default Label
