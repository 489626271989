/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import "moment/locale/it"
import "~styles/main.scss"
import { AuthConsumer } from "~contexts/auth"
import Button from "~components/_layout/button"
import mainCss, { footerCss, spectatorHeaderCss } from "./index.styles"
import Header from "../header"
import { FormattedMessage } from "react-intl"

moment.locale("it") // @todo: dynamic language

const Layout = ({
  children,
  showHeader = true,
  styleProps = {},
  gameStatus,
}) => (
  <>
    <div id="layout" className={mainCss(styleProps, gameStatus)}>
      {gameStatus === "user-spectator" && (
        <div className={spectatorHeaderCss}>
          <FormattedMessage id="layout.spectator_mode" />
        </div>
      )}
      {showHeader && <Header />}
      <div>
        <main>{children}</main>
      </div>

      <AuthConsumer>
        {({ isAuth, logout }) =>
          isAuth ? (
            <footer className={footerCss}>
              <div className="container has-text-centered">
                <div className="margin-bottom-24">
                  <a
                    className="has-text-white"
                    href="//assets.ctfassets.net/nd9wb0fnafic/7dK4mMZ1LL5LmDEXqAeAYe/71019c18cd86f1f458a34507b07ea01f/Informativa__BO_quiztime_01_08_2019_clean.pdf"
                  >
                    Informativa privacy
                  </a>{" "}
                  &middot;{" "}
                  <a
                    className="has-text-white"
                    href="//assets.ctfassets.net/nd9wb0fnafic/3epVzUtSdyZTFDIJMTHdH9/ce1d19a4a0c9ffeca11953560ccd8246/REGOLAMENTO_DISCOVERY_LIVE_QUIZ_BAKE_OFF_7_ok_mod.pdf"
                  >
                    Regolamento
                  </a>
                </div>

                <div>
                  <Button
                    className="is-link is-inverted is-outlined"
                    onClick={() => logout()}
                    localization={false}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </footer>
          ) : null
        }
      </AuthConsumer>
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
