import { css, cx } from "emotion"
import vars from "~utils/variables"

export default cx(
  css`
    &.is-white-transparent {
      background-color: rgba(255, 255, 255, 0.2);
      color: ${vars.white};
    }

    &.is-life-red {
      background-color: ${vars.lifeRed};
      color: ${vars.white};
    }

    &.is-light-blue {
      background-color: #eff5fe;
      color: #83b3fa;
    }

    &.is-gold {
      background-color: ${vars.gold};
      color: ${vars.white};
    }

    &.is-spectator {
      background-color: #e0dfff;
      color: ${vars.purple};
    }
  `,
  "tag"
)
