import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import config from "../../../config"

const SEO = ({
  title,
  description,
  image,
  imageWidth,
  imageHeight,
  pathname,
  article,
}) => {
  const seo = {
    title,
    description,
    image,
    imageWidth,
    imageHeight,
    pathname,
    article,
  }

  return (
    <>
      <Helmet title={seo.title}>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {seo.url && <meta property="og:url" content={seo.url} />}
        {(article ? true : null) && (
          <meta property="og:type" content="article" />
        )}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}
        {seo.imageWidth && (
          <meta property="og:image:width" content={seo.imageWidth} />
        )}
        {seo.imageHeight && (
          <meta property="og:image:height" content={seo.imageHeight} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
      </Helmet>
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: config.name,
  description: null,
  image: null,
  imageWidth: null,
  imageHeight: null,
  pathname: null,
  article: false,
}
