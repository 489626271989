import { css, cx } from "emotion"
import vars from "~utils/variables"

export const footerCss = cx(
  css`
    background-color: rgba(255, 255, 255, 0.2);
  `,
  "footer",
  "padding-top-48",
  "padding-bottom-48"
)

export const spectatorHeaderCss = cx(
  css`
    background-color: rgba(0, 0, 0, 0.1);
  `,
  "has-text-weight-bold",
  "has-text-centered",
  "has-text-white",
  "is-uppercase",
  "is-size-7",
  "padding-8"
)

export default ({ backgroundColor, backgroundImage }, gameStatus) => {
  const statusColors = {
    "user-won": vars.success,
    "user-lost": vars.loser,
    "user-spectator": vars.spectator,
    "user-playing": backgroundColor,
  }

  return cx(css`
    background-color: ${statusColors[gameStatus] ||
      backgroundColor ||
      vars.primary};
    ${backgroundImage
      ? `background-image: url(${backgroundImage.file.url});`
      : ""}
    min-height: 100vh;
    background-size: auto;
    background-position: center center;
    transition: background-color 1s ease;
  `)
}
