import { css, cx } from "emotion"
import vars from "~utils/variables"

export default cx(
  css`
    &.is-pending {
      background-color: ${vars.pending};
      border-color: ${vars.pending};
      color: ${vars.white};

      &:disabled {
        opacity: 1;
      }
    }
  `,
  "button"
)
