import React from "react"
import { FormattedMessage } from "react-intl"
import mainCss from "./index.styles"

const Button = ({
  className = "",
  onClick,
  type = "button",
  disabled = false,
  localization = true,
  children,
  testId,
}) => (
  <button
    type={type}
    onClick={onClick}
    className={`${mainCss} ${className}`}
    disabled={disabled}
    data-testid={testId}
  >
    {localization ? <FormattedMessage id={children} /> : children}
  </button>
)

export default Button
