import { Link } from "gatsby"
import Tag from "~components/_layout/tag"
import PropTypes from "prop-types"
import React from "react"

const Header = () => (
  <header className="padding-16">
    <Link to="/faq">
      <Tag
        className="is-white-transparent has-text-weight-bold is-size-7"
        id="faq"
      >
        FAQ <i className="fas fa-question-circle margin-left-8"></i>
      </Tag>
    </Link>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
