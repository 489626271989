import React from "react"
import { Link, graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import { AuthConsumer } from "~contexts/auth"
import Layout from "~components/_layout"
import SEO from "~components/_layout/seo"
import Register from "~components/register"
import { logoCss } from "./index.styles"

const RegisterPage = ({ data }) => (
  <Layout
    styleProps={{
      backgroundColor: data.contentfulCustomer.backgroundColor,
      backgroundImage: data.contentfulCustomer.backgroundImage,
      minHeight100: true,
    }}
    showHeader={false}
  >
    <SEO title={`${data.contentfulCustomer.title} | QuizTime`} />
    <div className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-4-desktop is-offset-4-desktop has-text-centered">
            <div
              className={logoCss({
                width: data.contentfulCustomer.logoWidthAwaitingStartSection,
              })}
            >
              <img src={data.contentfulCustomer.logo.resize.src} alt="Logo" />
            </div>

            <div className="box">
              <AuthConsumer>
                {({ register, isAuth }) => (
                  <Register
                    agreements={data.contentfulCustomer.registrationAgreements}
                    register={register}
                    isAuth={isAuth}
                  />
                )}
              </AuthConsumer>
              <p className="margin-top-24">
                <Link to="/">
                  <FormattedMessage id="register_page.already_registered" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default RegisterPage

export const pageQuery = graphql`
  query($customerId: Int) {
    contentfulCustomer(customerId: { eq: $customerId }) {
      title
      domain
      logo {
        resize(width: 300, quality: 100) {
          src
        }
      }
      logoWidthAwaitingStartSection
      backgroundColor
      backgroundImage {
        file {
          url
        }
      }
      registrationAgreements {
        text {
          childMarkdownRemark {
            html
          }
        }
        isRequired
      }
    }
  }
`
